#navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 0 60px 0;
}

#navbar ul {
  display: flex;
  justify-content: center;
  gap: 60px;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

#navbar ul li {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 10px;
  color: #4e5b51;
  transition: border-bottom 0.3s ease;
  border-bottom: 2px solid transparent;
  text-decoration: none;
  cursor: pointer;
  font-size: 20px;
}

#navbar ul li.selected,
#navbar ul li:hover {
  border-bottom: 2px solid #4e5b51;
}

@media (max-width: 800px) {
  #navbar ul {
    flex-wrap: wrap;
    width: 500px;
    gap: 28px;
    font-size: 16px;
  }

  #navbar ul li {
    font-size: 18px;
  }
}

@media (max-width: 500px) {
  #navbar ul {
    width: 375px;
  }

  #navbar ul li {
    font-size: 16px;
  }
}
