#layout {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#layout .initials {
  height: 150px;
  width: 300px;
  padding: 80px 0 40px 0;
}

#layout .title {
  font-size: 58px;
  text-transform: uppercase;
  font-weight: 300;
  letter-spacing: 4px;
  text-align: center;
}

#layout .details1,
#layout .details2 {
  font-size: 21px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 3px;
  line-height: 20px;
  font-weight: 500;
  margin: 0 0 10px 0;
}

@media (max-width: 1200px) {
  #layout .title {
    width: 780px;
    font-size: 50px;
  }

  #layout .details1,
  #layout .details2 {
    font-size: 18px;
  }
}

@media (max-width: 800px) {
  #layout .title {
    width: 480px;
    font-size: 50px;
  }

  #layout .details1,
  #layout .details2 {
    font-size: 18px;
  }
}

@media (max-width: 500px) {
  #layout .title {
    width: 370px;
    font-size: 42px;
  }

  #layout .details1,
  #layout .details2 {
    font-size: 18px;
  }
}
