.languageChooser {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  top: 0;
  right: 0;
  height: 30px;
  padding-top: 20px;
  padding-right: 20px;
}

.languageChooser .languageButton {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.languageChooser .languageButtonSeparator {
  height: 100%;
  border-right: 1px solid black;
}
