#rsvp {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 40px;
  width: 100%;
}

#rsvp h1 {
  margin: 10px;
}

#rsvp .cancel {
  position: absolute;
  top: 12px;
  right: 12px;
  width: 24px;
  height: 24px;
  border: 0;
  background-color: #4e5b51;
  color: #fff;
}

#rsvp .rsvpBody,
#rsvp .rsvpHead {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
}

#rsvp .rsvpHead {
  height: 100px;
  justify-content: flex-start;
}

#rsvp .rsvpBody {
  justify-content: center;
  min-height: calc(100vh - 100px);
}

@media (max-width: 700px) {
  .simpleCard .title,
  .simpleCard .subtitle {
    text-align: center;
  }
}
