.accomodationConfirmation {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.accomodationConfirmation .accomodationConfirmationButtons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.accomodationConfirmation .accomodationConfirmationButtons button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 40px;
  color: #4e5b51;
  background-color: #fff;
  border: 1px solid #4e5b51;
  font-weight: 600;
}

.accomodationConfirmation .accomodationConfirmationButtons .selected,
.accomodationConfirmation .accomodationConfirmationButtons button:hover {
  color: #fff;
  background-color: #4e5b51;
}
