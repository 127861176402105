.simpleCard {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 40px;
  min-height: 400px;
  width: 550px;
}

.simpleCard .title {
  font-size: 24px;
  margin: 0;
}

.simpleCard .subtitle {
  font-size: 18px;
  font-weight: 300;
  margin: 0;
}

.simpleCard button {
  color: #fff;
  background-color: #4e5b51;
  border: 0;
  cursor: pointer;
  transition: background-color 0.5s ease;
}

.simpleCard .rsvpActionButton {
  height: 50px;
  width: 100%;
  font-size: 18px;
  letter-spacing: 2px;
  color: #fff;
  background-color: #4e5b51;
  border: 0;
  cursor: pointer;
  transition: background-color 0.5s ease;
}

.simpleCard .removeGuestText {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.simpleCard .removeGuestText .rsvpFirstCancelCustomField {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  width: 24px;
  height: 24px;
  font-size: 12px;
  border-radius: 50%;
}

.simpleCard button:hover {
  background-color: #39423b;
}

.simpleCard .validationError {
  margin: 0;
  color: red;
}

@media (max-width: 700px) {
  .simpleCard {
    width: 480px;
  }
}

@media (max-width: 500px) {
  .simpleCard {
    width: 360px;
  }
}
