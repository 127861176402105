#footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#footer h3 {
  font-size: 60px;
  font-weight: 300;
  text-transform: uppercase;
  margin: 20px;
  letter-spacing: 3px;
}

#footer .separator {
  width: 200px;
  border-bottom: 1px solid #4e5b51;
  margin: 0;
}

#footer h4 {
  font-size: 20px;
  text-transform: uppercase;
  margin: 20px;
}
